<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if="mobile === false" src="../../../../../assets/images/banners/startup.png" alt="banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/startups.png" alt="banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>PRAWO GOSPODARCZE</h4>
		</template>

		<template #title>
			<h2>Startupy</h2>
		</template>

		<template #text>
			<p>Oferujemy kompleksowe wsparcie dla start-upów, wiedząc jak trudne są początki,
				uczestniczymy w rozwoju nowego biznesu naszych Klientów począwszy od wstępnego pomysłu i zabezpieczenia
				go prawami własności intelektualnej, poprzez bieżące wsparcie prawne oraz pomoc w wyborze optymalnej
				formy prawnej, a także sporządzaniu umów niezbędnych w początkowej fazie działalności.
			</p>

			<p>

				Wspieramy od strony prawnej start-upy w zakresie formowania relacji z funduszami
				inwestycyjnymi, w szczególności w ramach programów realizowanych przy użyciu środków pochodzących z
				Narodowego Centrum Badań i Rozwoju oraz Polskiego Funduszu Rozwoju.
				Należy pamiętać, że konfrontacja początkującego przedsiębiorcy z doświadczonym inwestorem wymaga
				profesjonalnego wsparcia prawnego, bowiem niekiedy nieświadoma akceptacja narzuconych warunków może
				prowadzić do niekorzystnych konsekwencji.
			</p>

			<p>
				Zapewniamy również wsparcie związane z funkcjonowaniem powstałej spółki na każdym etapie jej dalszego
				rozwoju.</p>
		</template>

		<template #range>
			<h3>Zakres:</h3>
		</template>
		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Proces inwestycyjny</li>
				<li class="offer__list-item">Program opcyjny</li>
				<li class="offer__list-item">Bieżąca obsługa spółek</li>
				<li class="offer__list-item">Przygotowanie regulaminów i audytów </li>
				<li class="offer__list-item">Rejestracja spółek</li>
				<li class="offer__list-item">Przekształcenia, połączenia i podział spółek </li>
				<li class="offer__list-item">Struktury międzynarodowe</li>
				<li class="offer__list-item">Własność intelektualna</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	components: {
		Offer
	},
	name: 'startup',
	mixins: [handleWith],
}
</script>
